// extracted by mini-css-extract-plugin
export var container = "kennissessies-module--container--f1cf3";
export var productCard = "kennissessies-module--product-card--97dbe";
export var productCardAction = "kennissessies-module--product-card-action--85103";
export var productCardButton = "kennissessies-module--product-card-button--28345";
export var productCardCompanyLogo = "kennissessies-module--product-card-company-logo--130ae";
export var productCardDescription = "kennissessies-module--product-card-description--3e1c7";
export var productCardImage = "kennissessies-module--product-card-image--539f1";
export var productCardRow = "kennissessies-module--product-card-row--bc618";
export var productCardText = "kennissessies-module--product-card-text--45c44";
export var productCardTitle = "kennissessies-module--product-card-title--7af5c";
export var productCounter = "kennissessies-module--product-counter--24cdc";
export var productPageHeader = "kennissessies-module--product-page-header--c1d51";
export var productPageHeaderImage = "kennissessies-module--product-page-header-image--515d8";
export var productPageHeaderText = "kennissessies-module--product-page-header-text--caa35";
export var productsGrid = "kennissessies-module--products-grid--f1080";
export var productsMain = "kennissessies-module--products-main--2330b";
export var productsPage = "kennissessies-module--products-page--f4d19";